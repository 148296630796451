<template>
    <div>
        <div class="Bussiness_Content">
      <div class="TitleBox">
        <h2><span>主营业务</span></h2>
        <h4>Main business</h4>
      </div>
      <div class="Bussiness_Box" style="display: flex">
        <div style="margin-right: 35px">
          <img src="../../../../assets/Bussiness_left.png" alt="" />
        </div>
        <div style="display: flex; justify-content: center; min-width: 972px">
          <div
            v-for="(item, index) in menu"
            :key="index"
            class="Bussiness_Car_Box"
          >
            <div class="Buissiness_Img1">
              <img :src="item.img">
            </div>
            <div class="Bussiness_Car_title">
              <div>
                <h4>{{item.name}}</h4>
                <h5>{{item.Ename}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 35px">
          <img src="../../../../assets/Bussiness_right.png" alt="" />
        </div>
      </div>
      <div class="Bussiness_Car_Slider">
       <!-- <div></div>
        <div></div>
        <div></div>
        <div></div> -->
      </div>
    </div>
    </div>
</template>

<script>
const menu1 = require("../../../../assets/menu1.png");
const menu2 = require("../../../../assets/menu2.png");
const menu3 = require("../../../../assets/menu3.png");
const menu4 = require("../../../../assets/menu4.png");
const menu5 = require("../../../../assets/menu5.png");
const menu6 = require("../../../../assets/menu6.png");


export default {
    data() {
		return {
			menu:[
				{
					name:'空调维修',
					Ename:'Air conditioning maintenance',
					img:menu4
				},
				{
					name:'空调清洗',
					Ename:'Air conditioning cleaning',
					img:menu3
				},
				{
					name:'油烟机清洗',
					Ename:'Range hood cleaning',
					img:menu1
				},
				{
					name:'洗衣机维修',
					Ename:'Washing machine maintenance',
					img:menu2
				},
				{
					name:'冰箱清洗',
					Ename:'Refrigerator cleaning',
					img:menu5
				},
				{
					name:'冰柜清洗',
					Ename:'Freezer cleaning',
					img:menu6
				}
			]
		}
    },
}
</script>

<style scoped>
.TitleBox {
  padding: 53px 0px;
}
.TitleBox h2 {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TitleBox h4 {
  font-size: 11px;
  margin-top: 10px;
}
.TitleBox h2 span {
  display: block;
  position: relative;
}
.TitleBox h2 span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -150px;
  width: 87px;
  height: 3px;
  background-color: #f2f2f2;
}

.TitleBox h2 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -150px;
  width: 87px;
  height: 3px;
  background-color: #f2f2f2;
}


.Bussiness_Car_title {
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Bussiness_Car_title h4 {
  font-size: 14px;
}
.Bussiness_Car_title h5 {
  font-size: 11px;
  color: #999999;
}

.Bussiness_Car_Box {
  margin: 0px 12px;
  width: 213px;
  box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.09);
}

.Buissiness_Img1 {
  width: 212px;
  height: 214px;
  /* background: url("../../../../assets/Car_01.png") no-repeat center center; */
  background-size: cover;
}
.Buissiness_Img1 img {
	width: 212px;
	height: 214px;
}
.Bussiness_Box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bussiness_Car_Slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 145px;
}
.Bussiness_Car_Slider div {
  width: 9px;
  height: 9px;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  margin: 0px 8px;
}
.Bussiness_Car_Slider div:first-child {
  border: 1px solid #19b955;
  background-color: #19b955;
}
</style>