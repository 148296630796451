<template>
    <div>
         <div class="Intro_Module_one">
      <div class="Intro_Bg_Img"></div>
      <div class="Intro_Title">
        <div>Renlee Consulting</div>
        <div>东修西修·不如依修</div>
        <div>
          <div></div>
        </div>
        <div>
          <p>
            仁略咨询一方面专注于全面激励领域，另一方面专注于专业提升和服务价值提升以客户价值为专业发展出发点
          </p>
        </div>
      </div>
      <div class="Intro_posBox">
        <div class="Title">特色、实惠、服务</div>
        <div class="Datas">
          <div>
            <div>商家</div>
            <div>94.230家</div>
          </div>
          <div>
            <div>服务次数</div>
            <div>28.936.478次</div>
          </div>
          <div>
            <div>生厂商</div>
            <div>20.065.9家</div>
          </div>
        </div>
      </div>
    </div>

    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.Intro_Module_one {
  width: 100%;
  height: 694px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Intro_Module_one .Intro_Bg_Img {
  position: absolute;
  top: 0px;
  z-index: -1;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: url("../../../../assets/Intor_Bg.png") no-repeat center center;
  background-size: cover;
}

.Intro_Title div:nth-child(1) {
  font-size: 56px;
  color: #fff;
  font-weight: 500;
  font-family: "SourceHanSansCN-Normal";
}

.Intro_Title div:nth-child(2) {
  font-size: 37px;
  color: #fff;
  margin-top: 30px;
}
.Intro_Title div:nth-child(3) {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Intro_Title div:nth-child(3) div {
  width: 33px;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
}

.Intro_Title div:nth-child(4) p {
  width: 439px;
  color: #fff;
  font-size: 16px;
}

.Intro_posBox {
  width: 925px;
  height: 200px;
  padding: 22px;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  bottom: -120px;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.04);
}

.Intro_posBox .Title {
  color: #00bb2c;
  font-size: 19px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Intro_posBox .Datas {
  display: flex;
}
.Intro_posBox .Datas > div {
  flex: 1;
  position: relative;
}
.Intro_posBox .Datas > div > div:first-child {
  color: #5f616d;
  font-size: 15px;
  margin-top: 10px;
}
.Intro_posBox .Datas > div > div:nth-child(2) {
  font-size: 39px;
  color: #00bb2c;
  margin-top: 15px;
  font-weight: 600;
}

.Intro_posBox .Datas > div:nth-child(1)::before {
  content: "";
  height: 108px;
  width: 1px;
  background: #dcdcdc;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.Intro_posBox .Datas > div:nth-child(2)::before {
  content: "";
  height: 108px;
  width: 1px;
  background: #dcdcdc;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
</style>