<template>
    <div>
        <div class="Team_Module">
      <div class="Title">专业的维修团队</div>
      <div class="Title2">专业售后团队保障您的权益</div>
      <div class="Content">
        <p>
          您是否为家庭的家电等故障维修问题而苦恼？我公司提供专业维修团队对针家电维修清洗上门服务，专业的售后团队保障您的权益
        </p>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.Team_Module {
  padding: 24px;
  background: #f5f5f5;
}
.Team_Module .Title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.Team_Module .Title2 {
  color: #666;
  font-size: 12px;
  margin-top: 10px;
}

.Team_Module p {
  width: 390px;
  font-size: 9px;
  color: #666666;
}

.Team_Module .Content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
}

</style>