<template>
	<div>
		<div class="Conpany_Module">
			<div class="TitleBox">
				<h2><span>YIXIU 企业介绍</span></h2>
				<h4>Enterprise introduction</h4>
			</div>

			<div class="SwiperBox">
				<div style="width: 950px;">
					<div style="width: 377px">
						<div style="width: 377px">
							<el-carousel height="420px" indicator-position="none" arrow="never">
								<el-carousel-item v-for="item in 4" :key="item">
									<img src="../../../../assets/Company.png" alt="" style="height: 520px;">
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
					<div class="" style="width:430px;margin-left:88px">
						<div class="title">
							<div>企业介绍</div>
							<div>Introduce</div>
						</div>
						<div class="content" style="height: 350px;">
							<p style="font-size: 12.8px;line-height: 20px;">
								福建依修网络科技有限公司成立于2014年11月。公司的前身为福建海峰机电设备服务有限公司。海峰机电于2003年成立，海峰机电公司集机电设备销售、维保、安装、为一体。公司下辖各部门，主要从事各类工业与家装项目的机电设备（大金、格力、海尔、TCL、三星、美的）销售、安装、电气安装、空调制冷工程、消防工程以及建筑智能化工程的设计与施工，各类机电设备配套完善，年施工施工产值达几千万元以上。随着近年网络科技的发展，为了解决多年来，机电行业一直存在的设备销售价格不透明，信息不对称，从业队伍混乱，监管难以到位，维修散乱，维修质量难以保障等等诸多弊病。本着为了千家万户（企业、公共机构和家庭）能更便利舒心的工作、生活作为出发点，一支有着15年销售、安装和售后维修服务经验与系统运营模式的专业团队，凭借良好的队伍素质、优良的施工设备、丰富的施工经验、雄厚的技术力量、科学的管理方法和先进的施工工艺，先后承建了大量的民用、公共设施及工业设备安装，维保工程通过不断地自我超越和资源整合，在传统专业市场、企业与未来智能商业之间架起一座桥梁，充分利用网络的先进技术、国际化的交易模式，帮助专业市场创新交易模式、管理模式、营销模式和流通模式
								，全力打造现代化、国际化、展贸化、电子化的专业市场交易平台。
							</p>
						</div>
						<!-- <div class="readMore">
							<img src="../../../../assets/aboutjian.png" alt="" />
							<span> READ MORE </span>
						</div> -->
						<div>
							<div>
								<img src="" alt="" />
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>

			<div class="TitleBox">
				<h2><span>NEWS 新闻中心</span></h2>
				<h4>Company news center</h4>
			</div>
			<div class="newsPlan" style="width: 925px;margin: 0 auto;height: 265px;">
				<router-link :to="{path:'/detail',query:{id:titleNew.id}}">
					<div class="newsTit boxs" style="width: 322px;height: 305px;cursor: pointer;">
						<div style="width: 322px;height: 196px;"><img :src="titleNew.image" /></div>
						<div class="newsPlan small " style="height: 114px;background: #f8f8f8;">
							<div style="width: 100px !important;color: #333333;text-align: center;">
								<p style="font-weight: bold;font-size: 28px;">22</p>
								<p style="font-size: 10px;">10月</p>
							</div>
							<div class="lineFeed" style="width: 280px;">
								<p class="nowraps" style="width: 220px;color:#333333;font-size: 18px;">{{titleNew.title}}</p>
								<p style="font-size: 12px;-webkit-line-clamp:3;line-height:20px;font-weight: normal;" class="lineFeed2">{{titleNew.keys}}</p>
							</div>
						</div>
					</div>
				</router-link>
				<div class="box-rows">
					<div class="rows" v-for="(item,index) in news" :key="index" @click="jump(item.id)" style="cursor: pointer;">
						<template v-if="index<3" >
							<div style="width: 100px;">
								<p style="font-weight: bold;font-size: 28px;">22</p>
								<p style="font-size: 10px;">10月</p>
							</div>
							<div class="lineFeed" style="width: 500px;">
								<p style="font-size: 18px;text-align: start;">{{item.title}}</p>
								<p class="lineFeed2" style="font-size: 12px;line-height:20px;color: #999999;">{{item.keys}}</p>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import * as api from '@/api/api.js'
	export default {
		data() {
			return {
				news: '',
				titleNew: '',
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			jump(id){
				console.log(id,'----88-8---')
				this.$router.push({path:"/detail",query:{id:id}});
			},
			getData() {
				var that = this;
				console.log(api)
				var params = {
					type_id: 2,
					pageShowNum: 4,
					currentPage: 1
				}
				axios.post(api.url + api.article, params).then(function(res) {
					that.news = res.data.data.list
					that.titleNew = res.data.data.list[3]
				})
			}
		}
	};
</script>

<style scoped>
	.title {
		text-align: start;
	}

	.readMore {
		text-align: start;
		font-weight: bold;
		font-size: 12px;
	}

	.content {
		padding: 20px 0;
		text-align: start;
		color: #666666;
		font-size: 12px;
	}

	.title>div:first-child {
		font-weight: bold;
		font-size: 14px;
	}

	.title>div:nth-child(2) {
		font-size: 28px;
		font-weight: 600;
	}

	.TitleBox {
		padding: 53px 0px;
	}

	.TitleBox h2 {
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.TitleBox h4 {
		font-size: 11px;
		margin-top: 10px;
	}

	.TitleBox h2 span {
		display: block;
		position: relative;
	}

	.TitleBox h2 span::after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: -150px;
		width: 87px;
		height: 3px;
		background-color: #f2f2f2;
	}

	.TitleBox h2 span::before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		right: -150px;
		width: 87px;
		height: 3px;
		background-color: #f2f2f2;
	}

	.Conpany_Module {
		padding-top: 160px;
	}

	.SwiperBox {
		display: flex;
		justify-content: center;
	}

	.SwiperBox>div {
		display: flex;
	}

	.newsTit>div:first-child img {
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: cover;
	}

	.newsPlan {
		display: flex;
	}

	.newsPlan>div:first-child {
		width: 40%;
	}

	.newsPlan>div:nth-child(2) {
		width: 60%;
	}

	.small>div:first-child {
		text-align: end;
		padding: 35px 0;
	}

	.small>div:nth-child(2) {
		width: 100%;
		word-wrap: break-word;
		word-break: normal;
		text-align: start;
		padding: 10px 10px 0 0px;
		height: 90px;
		-webkit-line-clamp: 4;
	}

	.lineFeed {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.lineFeed2 {
		/* word-wrap: break-word;
		word-break: normal;
		height: 80px;
		-webkit-line-clamp: 3; */
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.small>div:nth-child(2)>p:first-child {
		font-weight: bold;
		padding: 0 0 10px 0;
		font-size: 18px;
	}

	.small>div:nth-child(2)>p:nth-child(2) {
		font-size: 14px;
		color: #999999;
	}

	.boxs {
		box-shadow: 3px 10px 5px #f1f1f1;
	}

	.rows {
		box-shadow: 3px 2px 5px #f1f1f1;
		background: #f8f8f8;
		margin-bottom: 10px;
	}

	.rows>div {
		height: 96px;
	}

	.rows>div:first-child {
		width: 80px;
	}

	.box-rows {
		width: 453px;
		margin-left: 30px;
	}

	.box-rows>div {
		display: flex;
	}

	.rows>div:first-child {
		width: 100px;
		height: 50px !important;
		padding-top: 20px;
	}

	.rows>div:nth-child(2)>p:first-child {
		font-weight: bold;
		padding: 10px 0 10px;
	}

	.rows>div:nth-child(2) {
		margin: 0 20px 0 0;
	}

	.rows>div:nth-child(2)>p:nth-child(2) {
		color: #999999;
		font-size: 13px;
	}

	.nowraps {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.rows:hover {
		background: #ffffff;
	}
</style>
