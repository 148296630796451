import { render, staticRenderFns } from "./ConpanyComp.vue?vue&type=template&id=6a2481c0&scoped=true&"
import script from "./ConpanyComp.vue?vue&type=script&lang=js&"
export * from "./ConpanyComp.vue?vue&type=script&lang=js&"
import style0 from "./ConpanyComp.vue?vue&type=style&index=0&id=6a2481c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2481c0",
  null
  
)

export default component.exports