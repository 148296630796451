<template>
  <div style="position: relative;">
    <div>
      <el-carousel height="766px">
        <el-carousel-item v-for="(item,index) in list" :key="index">
          <div class="Swiper_Module">
			  <img :src="item.url" />
            <!-- <div class="Swiper_slider"></div> -->
          </div>
		  
        </el-carousel-item>
      </el-carousel>
    </div>
	<div class="title-text">
		<h1>民众生活好帮手</h1>
		<h2>东修西修 
			<span style="font-size: 20px;padding: 0 10px;">●</span>
		 	 不如依修
		</h2>
		<div class="block"></div>
		<h3>福建依修网络科技有限公司是一家专注于家电维修的综合性共享维修服务平台。</h3>
	</div>
  </div>
</template>
<script>
const img1 = require('../../../../assets/lb1.jpg');
// const img2 = require('../../../../assets/Swiper-01.png');
const img3 = require('../../../../assets/Intor_Bg.png');
const img2 = require('../../../../assets/Swiper-03.jpg');
export default {
	data() {
		return {
			list:[
				{url:img1},
				{url:img2},
				{url:img3},
			]
		}
	},
	methods:{
		
	}
};
</script>
<style scoped>
/* 轮播模块 */
.swiper-container {
  width: 500px;
  height: 300px;
  margin: 20px auto;
}
.Swiper_Module {
  width: 100%;
  height: 766px;
  object-fit: cover;
  position: relative;
}
.Swiper_Module img {
	width: 100%;
	height: auto;
}
.Swiper_slider {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  /* background: url("../../../../assets/Swiper-01.png") no-repeat center center; */
  /* background-size: 100% 100%; */
}
.title-text {
	color: #ffffff;
	position: absolute;
	left: 50%;
	top: 40%;
	z-index: 999;
	transform: translate(-50%,-50%);
	
}
.title-text > h1 {
	font-size: 82px;
}
.title-text > h2 {
	padding-top: 48px;
	font-weight: 100;
	font-size: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.title-text > h3 {
	font-size: 16px;
	font-weight: 100;
}
.block {
	background: #ffffff;
	width: 44px;
	height: 6px;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 30px;
}
</style>