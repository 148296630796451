<template>
  <div>
    <!-- 顶部轮播图模块 -->
    <swiper-comp/>
    <!-- 主营业务模块 -->
    <bussiness-comp/>
   
    <!-- 团队简介模块 -->
    <team-comp/>
 

    <!-- 依修简介1 -->
    <intro-one-comp/>
    

    <!-- 企业介绍模块 -->
    <conpany-comp/>
	
	<bottom/>
	
  </div>
</template>
<script>

import Swiper from "swiper"
import SwiperComp from './components/SwiperComp.vue'
import BussinessComp from './components/BussinessComp.vue'
import TeamComp from './components/TeamComp.vue'
import IntroOneComp from './components/IntroOneComp.vue'
import ConpanyComp from './components/ConpanyComp.vue'
import bottom from './components/bottom.vue'
export default {
  data() {
    return {};
  },
  components:{
    SwiperComp,
    BussinessComp,
    TeamComp,
    IntroOneComp,
    ConpanyComp,
	bottom
  },
  mounted() {
    this.SwiperInit();

  },
  methods: {
    SwiperInit() {
      new Swiper(".swiper-container", {
        autoplay: 5000, //可选选项，自动滑动
      });
    },
  },
};
</script>

<style >

</style>
